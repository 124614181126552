import * as React from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { Grid } from "@mui/material";
import {
  accountMenuItems,
  menuItemsCommon
} from "../../Constants/LayoutCommon";
import { menuItemsAdmin } from "../../Constants/LayoutAdmin";
import { menuItemsManager } from "../../Constants/LayoutManager";
import { menuItemsAgent } from "../../Constants/LayoutAgent";
import { useLocation } from "react-router-dom";
import Dashboardd from "../../Pages/Admin/Dashboard/Dashboard";
import Newdashboard from "../../Pages/Admin/NewDashBoard/Dashboard"
import ManagePolicyType from "../../Pages/Admin/master/PolicyType/ManagePolicyType";
import Notification from "../../Pages/Admin/master/Notification/ManageNotification";
import ManageCompany from "../../Pages/Admin/master/Company/ManageCompany";
import ManageCompanyCode from "../../Pages/Admin/master/CompanyCode/ManageCompanyCode";
import ManageProduct from "../../Pages/Admin/master/Product/ManageProduct";
import ManageCompanyLogin from "../../Pages/Admin/master/CompanyLogin/ManageCompanyLogin";
import Master from "../../Pages/Admin/master/Branch/ManageBranch";
import RTO from "../../Pages/Admin/master/Rto/ManageRto";
import Year from "../../Pages/Admin/master/Year/ManageYear"
import Make from "../../Pages/Admin/master/Make/ManageMake"
import Reports from "../../Pages/Report/Reports";
//common
import PolicyView from "../../Pages/PolicyView/ManagePolicyView";
import PolicyAgent from "../../Pages/PolicyAgent/PolicyAgent";
import PolicyEntryPending from "../../Pages/PolicyEntryPending/ManagePolicyEntryPending";
import AddPolicCreate from "../../Pages/PolicyCreate/AddPolicyCreate";
import Report from "../../Pages/BookingData/Report";
import ManageUser from "../../Pages/AddUser/ManagerUser";
import UserConfig from "../../Pages/UserConfig/ManageUserConfig";
import CompanyContact from "../../Pages/CompanyContact/ManageCompanyContact.js";
import CompanyLogin from "../../Pages/CompanyLoginMain/CompanyLoginMain";
import SearchPolicy from "../../Pages/SearchPolicy/ManageSearchPolicy";
//agent
import ManageWallet from "../../Pages/Wallet/ManageWallet";
import ManageRenewal from "../../Pages/Renewal/ManageRenewal";
import Payout from "../../Pages/Payout/Payout";
import Profile from "../../Pages/Profile/Profile";
import Transaction from "../../Pages/Transaction/Transaction";
import ManagerWallet from "../../Pages/ManagerWallet/wallet";
import ManagerTransaction from "../../Pages/Transaction/managerTransaction";
//admin
import ManagePolicyApproved from "../../Pages/ApprovedPolicy/manageApprovedPolicy"
import PayoutPoints from '../../Pages/payoutPoints/payoutPoints'

const elements = {
  "/admin/dashboard": Dashboardd,
  "/manager/dashboard": Dashboardd,
  "/agent/dashboard": Dashboardd,
  "/admin/newdashboard":Newdashboard,
  "/manager/newdashboard": Newdashboard,
  // "/agent/newdashboard": Newdashboard,
  "/policytype": ManagePolicyType,
  "/policyview": PolicyView,
  "/SearchPolicy": SearchPolicy,
  "/branch": Master,
  "/rto": RTO,
  "/year": Year,
  "/make":Make,
  "/report": Report,
  "/notification": Notification,
  "/adduser": ManageUser,
  "/userconfig": UserConfig,
  "/company": ManageCompany,
  "/product": ManageProduct,
  "/companycode": ManageCompanyCode,
  "/companylogin": ManageCompanyLogin,
  "/policycreate": AddPolicCreate,
  "/policyEntryPending": PolicyEntryPending,
  "/policyagent": PolicyAgent,
  "/companycontact": CompanyContact,
  "/": Newdashboard,
  "/companyLogin": CompanyLogin,
  "/renewal": ManageRenewal,
  "/managewallet": ManageWallet,
  "/profile": Profile,
  "/payout": Payout,
  "/transaction": Transaction,
  "/reports": Reports,
  "/manager/wallet": ManagerWallet,
  "/manager/transaction": ManagerTransaction,
  "/approvedPolicy":ManagePolicyApproved,
  "/payoutPoints":PayoutPoints
};

export default function Dashboard() {
  const [currentEl, setCurrentEl] = React.useState();
  const location = useLocation();
  const role = localStorage.getItem("role")

  React.useEffect(() => {
    if (
      location.pathname &&
      Object.keys(elements).includes(location.pathname)
    ) {
      setCurrentEl(location.pathname);
    }
  }, [location]);

  React.useEffect(() => {
    if (currentEl) window.history.replaceState(null, currentEl.split('/')[1], currentEl);
  }, [currentEl]);

  React.useEffect(() => { }, [currentEl]);

  const Element = elements[currentEl] && elements[currentEl];

  return (
    <>
      <Sidebar
        navTitle={null}
        menuItems={(role === "admin") ? menuItemsAdmin : (role === "manager") ? menuItemsManager : (role === "agent") ? menuItemsAgent : menuItemsCommon}
        accountMenuItems={accountMenuItems}
        setCurrentMenu={setCurrentEl}
      >
        <Grid container p={{ xs: 0, md: 0 }}>
          <Grid item xs={12}>
            {Element && <Element />}
          </Grid>
        </Grid>
      </Sidebar>
    </>



  );
}
