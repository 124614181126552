import React, { useEffect, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Formik, Form, Field } from "formik";
import { Button, Select, Input, message, Upload, DatePicker } from "antd";
import { FloatLabel } from "../../UIComponent/_index";
import { EditOutlined } from "@ant-design/icons";
import { policyCreateCompanyGet,getAllManager ,RtoGetByCompanyId, yearGetByCompanyId, makeGetByCompanyId} from "../../utils/servies/common";
import ConfirmBox from "../../UIComponent/ConfirmBox";
// import { ProductGet } from "../../utils/servies/common";
import { policyTypeGet, ProductGetByCompanyId, policyEntryAdmin , userConfigBookingCode} from "../../utils/servies/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Buttons from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import moment from "moment";
import { Stack } from "@mui/material";
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import {baseUrl} from "../../Constants/constants"
import { rtoapi } from "../../utils/servies/common";
import { yearapi } from "../../utils/servies/common";

const { Option } = Select;
const AddPolicyEntryPending = (props) => {
  const { formType, backToDashBoard, selectedPolicyPending, getvalue, backtoValue } = props;
  var viewpopulate = selectedPolicyPending;
  viewpopulate.userId =
    viewpopulate.userId.length > 0
      ? viewpopulate.userId[0]
      : viewpopulate.userId;
  const url = `${baseUrl}/api/updatePolicy`;
  const [rtoList, setRtoList] = useState();
  const [yearList, setYearList] = useState();
  const [makeList, setMakeList] = useState();
  const [data, setData] = useState(viewpopulate);
  const [saveStatus, setSaveStatus] = useState(viewpopulate?.status);
  const [mapCompany, setMapCompany] = useState();
  const [saveCompany, setSaveCompany] = useState(
    viewpopulate?.companyId?._id
      ? viewpopulate?.companyId?._id
      : viewpopulate?.companyId
        ? viewpopulate?.companyId[0]?._id
        : ""
  );
  const [mapProduct, SetMapProduct] = useState();
  const [saveProduct, setSaveProduct] = useState(
    viewpopulate?.product?._id
      ? viewpopulate?.product?._id
      : viewpopulate?.product
        ? viewpopulate?.product[0]?._id
        : ""
  );
  const [mapPolicyType, setMapPolicyType] = useState();
  const [mapBookingCode, setMapBookingCode] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [savePolicyType, setSavePolicyType] = useState(
    viewpopulate?.policyType?._id
      ? viewpopulate?.policyType?._id
      : viewpopulate?.policyType
        ? viewpopulate?.policyType[0]?._id
        : ""
  );
  const [saveBookingCode, setSaveBookingCode] = useState(
    viewpopulate?.companyCodeId?._id
      ? viewpopulate?.companyCodeId?._id
      : viewpopulate?.companyCodeId
        ? viewpopulate?.companyCodeId[0]?._id
        : ""
  );
  /// date 
  const [selectedDate, setSelectedDate] = useState(viewpopulate.policyDate);
  const [selectedDateOdStart, setSelectedDateOdStart] = useState(viewpopulate.odPolicyStartDate)
  const [selectedDateOdEnd, setSelectedDateOdEnd] = useState(viewpopulate.odPolicyEndDate)
  const [selectedDateTpStart, setSelectedDateTpStart] = useState(viewpopulate.tpPolicyStartDate)
  const [selectedDateTpEnd, setSelectedDateTpEnd] = useState(viewpopulate.tpPolicyEndDate)
  const dateFormat = 'DD-MM-YYYY'
  const [selectedRto,setSelectedRto]=useState(
    viewpopulate?.rtoLocation
      ? viewpopulate?.rtoLocation
      : viewpopulate?.rtoLocation
        ? viewpopulate?.rtoLocation[0]
        : ""
  );
  const [selectedYear,setSelectedYear]=useState(
    viewpopulate?.year
      ? viewpopulate?.year
      : viewpopulate?.year
        ? viewpopulate?.year[0]
        : ""
    );
    const [selectedMake,setSelectedMake]=useState(
      viewpopulate?.make
        ? viewpopulate?.make
        : viewpopulate?.make
          ? viewpopulate?.make[0]
          : ""
      );
  const [open, setOpen] = useState(false);
  const [openPending, setOpenPending] = useState(false);

  const [opens, setOpens] = useState(false);
  const [approve, setApprove] = useState({});
  const [updateDate, setUpdateDate] = useState();
  const [update, setUpdate] = useState(false);
  const [disabled, setDisabled] = useState(true);

  /// approve
  const [agentData, setAgentData] = useState(0);
  const [managerData, setManagerData] = useState(0);
  const [adminData, setAdminData] = useState(0);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [statusChange, setStatusChange] = useState();
  const [loader, setLoader] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [branchName, setBranchName] = useState("");

  ///
  const handleCancel = () => {
    backToDashBoard(backtoValue);
  };

  const clearOdData = () => {
    setSelectedDateOdStart('1970-01-01')
    setSelectedDateOdEnd('1970-01-01')
    setData({ ...data, odPeriod: "0" })
    setData({ ...data, odPolicyStartDate: '1970-01-01' })
    setData({ ...data, odPolicyEndDate: '1970-01-01' })

    handleDateTpPolicyStart(viewpopulate.tpPolicyStartDate ? viewpopulate.tpPolicyStartDate : new moment() )
    // setData({ ...data, tpPeriod:1 })
  }

  
  const backupODTPData = () => {
    handleDateTpPolicyStart(viewpopulate.tpPolicyStartDate ? viewpopulate.tpPolicyStartDate : new moment() )
    handleDateODPolicyStart(viewpopulate.odPolicyStartDate ? viewpopulate.odPolicyStartDate : new moment())
    // setData({ ...data, tpPeriod: 1 })
    // setData({ ...data, odPeriod: 1 })
  }

  const clearTpData = () => {
    setSelectedDateTpStart('1970-01-01')
    setSelectedDateTpEnd('1970-01-01')
    setData({ ...data, tpPeriod: "0" })
    setData({ ...data, tpPolicyStartDate: '1970-01-01' })
    setData({ ...data, tpPolicyEndDate: '1970-01-01' })

    // setData({ ...data, odPeriod: 1 })
    handleDateODPolicyStart(viewpopulate.odPolicyStartDate ? viewpopulate.odPolicyStartDate : new moment())
  }
  const getCompany = () => {
    policyCreateCompanyGet().then((res) => setMapCompany(res.data));
  };
  const getProduct = () => {
    ProductGetByCompanyId(saveCompany)
      .then((res) => {
        SetMapProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getProductBycomp = (id) => {
    ProductGetByCompanyId(id.key)
      .then((res) => {
        SetMapProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getRtoBycomp = (id) => {
    RtoGetByCompanyId(id.key)
      .then((res) => {
        setRtoList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getYearBycomp = (id) => {
    yearGetByCompanyId(id.key)
      .then((res) => {
        setYearList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMakeBycomp = (id) => {
    makeGetByCompanyId(id.key)
      .then((res) => {
        setMakeList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRto = () => {
    RtoGetByCompanyId(saveCompany).then(res => {
      console.log("Rto list",res.data)
      setRtoList(res.data)
    })
  }

  const getYear = () => {
    yearGetByCompanyId(saveCompany).then(res => {
      console.log("Year list",res.data)
      setYearList(res.data)
    }).catch(e => {
      setYearList([])
    })
  }

  const getMake = () => {
    makeGetByCompanyId(saveCompany).then(res => {
      console.log("Year list",res.data)
      setMakeList(res.data)
    }).catch(e => {
      setMakeList([])
    })
  }

  const getPolicyType = () => {
    policyTypeGet()
      .then((res) => {
        setMapPolicyType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBookingCodeList = (e) => {
    userConfigBookingCode(e.key)
      .then((res) => {
        setMapBookingCode(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllmangerData =async () => {
    getAllManager().then(async(res) => {
      setManagerList(res);
      let data = await res.find(ele => ele.branch._id == viewpopulate.userId.branch)
      setBranchName(data.branch.branchName)
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "x-access-token ": localStorage.getItem("token"),
    },
  };

  const DownloadFunction = () => {
    axios
      .get(
        `${baseUrl}/api/getpolicyFile?filename=${viewpopulate?.policyFile}`,
        {
          headers: {
            "x-access-token ": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        window.open(res.data?.data?.URL);
        toast.success(res?.data?.message);
      });

    setOpen(false);
    setOpenPending(false);
  };
  const handleUpdate = async () => {
    let formData = new FormData();
    console.log("managerData", managerData);
    if (
      saveCompany &&
      data?.policyNumber &&
      data?.customerName &&
      data?.RegistrationNumber &&
      data?.RegistrationYear &&
      data?.customerEmail &&
      data?.netPremium > 0 &&
      data?.tpPremium > 0 &&
      saveStatus
    ) {
      // let value = {
      //   _id: data._id,
      //   companyId: saveCompany,
      //   userId : viewpopulate?.userId?._id,
      //   policyNumber: data?.policyNumber,
      //   customerName: data?.customerName,
      //   RegistrationNumber: data?.RegistrationNumber,
      //   netPremium: data?.netPremium,
      //   odPremium: data?.odPremium,
      //   totalPremium: data?.totalPremium,
      //   tpPremium: data?.tpPremium,
      //   tax: data?.tax,
      //   customerEmail: data?.customerEmail,
      //   status: saveStatus,
      //   product: saveProduct,
      //   policyType: savePolicyType,
      // };
      formData.append("_id", data._id);
      formData.append("userId", viewpopulate?.userId?._id);
      formData.append("companyId", saveCompany);
      formData.append("policyDate", selectedDate);
      formData.append("policyNumber", data?.policyNumber);
      formData.append("customerName", data?.customerName);
      formData.append("RegistrationNumber", data?.RegistrationNumber);
      formData.append("RegistrationYear", data?.RegistrationYear);
      formData.append("netPremium", data?.netPremium);
      formData.append("odPremium", data?.odPremium ? data?.odPremium : 0);
      formData.append("totalPremium", data?.totalPremium ? data?.totalPremium : 0);
      formData.append("tpPremium", data?.tpPremium ? data?.tpPremium : 0);
      // formData.append("tax", data?.tax);
      formData.append("customerMobile", data?.customerMobile?.length ? data?.customerMobile : "");
      formData.append("customerEmail", data?.customerEmail);
      formData.append("status", saveStatus);
      formData.append("product", saveProduct);
      formData.append("policyType", savePolicyType);
      formData.append("companyCodeId", saveBookingCode);
      formData.append("agentShare", agentData);
      formData.append("adminShare", adminData);
      formData.append("managerShare", managerData);
      formData.append("odPolicyStartDate", selectedDateOdStart);
      formData.append("odPolicyEndDate", selectedDateOdEnd);
      formData.append("tpPolicyStartDate", selectedDateTpStart);
      formData.append("tpPolicyEndDate", selectedDateTpEnd);
      formData.append("makeModel", data?.makeModel);
      formData.append("gvw", data?.gvw);
      formData.append("cc", data?.cc);
      // formData.append("engineNumber", data?.engineNumber);
      formData.append("fuelType", data?.fuelType);
      // formData.append("chassisNumber", data?.chassisNumber);
      // formData.append("vehicleSubclass", data?.vehicleSubclass);
      formData.append("seatingCapacity", data?.seatingCapacity);
      formData.append("odPeriod", data?.odPeriod);
      formData.append("tpPeriod", data?.tpPeriod);
      formData.append("odDiscount", data?.odDiscount);
      // formData.append("ncb", data?.ncb);
      // formData.append("businessType", data?.businessType);
      // formData.append("RTO", data?.RTO);
      // formData.append("carrierType", data?.carrierType);
      formData.append("rtoLocation",
          data.rtoLocation._id ? data.rtoLocation._id :
            selectedRto.key ? selectedRto.key :
            selectedRto._id ? selectedRto._id : "");
      formData.append("year",
      data.year._id ? data.year._id :
        selectedYear.key ? selectedYear.key :
            selectedYear._id ? selectedYear._id : "");
            formData.append("make",
            data.make._id ? data.make._id :
              selectedMake.key ? selectedMake.key :
              selectedMake._id ? selectedMake._id :"");
      formData.append("cpaCover", data?.cpaCover);
      console.log("RTo loca",data.rtoLocation._id ? data.rtoLocation._id :
      selectedRto.key ? selectedRto.key :
      selectedRto._id ? selectedRto._id :"")
      formData.append("paymentMode", data?.paymentMode);
      if (data?.paymentMode === "cash") {
        formData.append("CashModeAmount", data?.CashModeAmount);
      }
      if (saveStatus === "rejected") {
        formData.append("RejectedRemarks", data?.RejectedRemarks);
      }
      if (selectedFile) {
        formData.append("policyFile", selectedFile);
      }
      setLoader(true);
      axios
        .post(url, formData, config)
        // policyEntryPendingUpdate(value)
        .then((res) => {
          toast.success(res?.data?.messge);
          setLoader(false)
          // getvalue();
          backToDashBoard(backtoValue);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          setLoader(false)
        });
    }else if (saveStatus==='rejected')
    {
      formData.append("_id", data._id);
      formData.append("userId", viewpopulate?.userId?._id);
      formData.append("companyId", saveCompany);
      formData.append("policyDate", selectedDate);
      formData.append("policyNumber", data?.policyNumber ? data?.policyNumber : "" );
      formData.append("customerName", data?.customerName ? data?.customerName : "");
      formData.append("RegistrationNumber", data?.RegistrationNumber? data?.RegistrationNumber :"");
      formData.append("RegistrationYear", data?.RegistrationYear ? data?.RegistrationYear : "");
      formData.append("netPremium", data?.netPremium ? data?.netPremium : 0);
      formData.append("odPremium", data?.odPremium ? data?.odPremium : 0);
      formData.append("totalPremium", data?.totalPremium ? data?.totalPremium : 0);
      formData.append("tpPremium", data?.tpPremium? data?.tpPremium : 0);
      formData.append("customerMobile", data?.customerMobile?.length ? data?.customerMobile : "");
      formData.append("customerEmail", data?.customerEmail? data?.customerEmail : "");
      formData.append("status", saveStatus);
      // formData.append("product", saveProduct);
      // formData.append("policyType", savePolicyType);
      // formData.append("companyCodeId", saveBookingCode);
      formData.append("agentShare", agentData);
      formData.append("adminShare", adminData);
      formData.append("managerShare", managerData);
      // formData.append("odPolicyStartDate", selectedDateOdStart);
      // formData.append("odPolicyEndDate", selectedDateOdEnd);
      formData.append("makeModel", data?.makeModel ? data?.makeModel : "");
      formData.append("gvw", data?.gvw ? data?.gvw : '');
      formData.append("cc", data?.cc ? data?.cc : '');
      formData.append("fuelType", "");
      formData.append("seatingCapacity", "");
      formData.append("cpaCover", data?.cpaCover ? data?.cpaCover : "");
      if (saveStatus === "rejected") {
        formData.append("RejectedRemarks", data?.RejectedRemarks);
      }
      setLoader(true);
      axios
        .post(url, formData, config)
        // policyEntryPendingUpdate(value)
        .then((res) => {
          toast.success(res?.data?.messge);
          setLoader(false)
          // getvalue();
          backToDashBoard(backtoValue);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
          setLoader(false)
        });
    }else {
      toast.warning("Please Fill Details");
      setLoader(false)
    }
  };
  useEffect(() => {
    getCompany();
    getProduct();
    getPolicyType();
    getRto()
    getYear()
    getMake()
    getAllmangerData()
    let valuecheck= viewpopulate?.companyId?._id
      ? viewpopulate?.companyId?._id
      : viewpopulate?.companyId
        ? viewpopulate?.companyId[0]?._id
        : ""
    if (valuecheck && valuecheck !== "") {
      getBookingCodeList({key:valuecheck})
    }
  }, []);

  const getApprove = () => {
    console.log(saveProduct);
    let pid = data?.product?._id
      ? data.product._id
      : data?.product?.length > 0
        ? data?.product[0]?._id
        : saveProduct;
    let potId = data?.policyType?._id
      ? data?.policyType?._id
      : data?.policyType?.length
        ? data?.policyType[0]?._id
        : savePolicyType
          ? savePolicyType
          : "";
    let compCodeId = data?.companyCodeId?._id
    ? data?.companyCodeId?._id
    : data?.companyCodeId?.length
      ? data?.companyCodeId[0]?._id
      : saveBookingCode
        ? saveBookingCode
        : "";
    let compId = data?.companyId?._id
      ? data?.companyId?._id
      : data?.companyId[0]?._id
        ? data?.companyId[0]?._id
        : saveCompany ? saveCompany : "";
    let recData = {
      ...data,
      policyId: data?._id,
      year: data.year._id ? data.year._id : selectedYear.key ? selectedYear.key : "",
      make:data.make._id ? data.make._id : selectedMake.key ? selectedMake.key : "",
      rtoLocation:data.rtoLocation._id ? data.rtoLocation._id : selectedRto.key ? selectedRto.key : "",
      productId: saveProduct? saveProduct : pid,
      policyType: savePolicyType ? savePolicyType : potId,
      userId: data?.userId?._id
        ? data?.userId?._id
        : data?.userId[0]?._id
          ? data?.userId[0]?._id
          : "",
      companyId: saveCompany ? saveCompany : compId,
      companyCodeId: saveBookingCode ? saveBookingCode : compCodeId
    };
    policyEntryAdmin(recData).then((res) => {
      console.log(res.data);
      setApprove(res.data);
      if (!res.error) {
        setAdminData(res.data.totalAdminShare);
        setAgentData(res.data.totalAgentShare);
        setManagerData(res.data.manager.totalManagerShare);
      }
    });
  };
  const Approved = () => {
    setOpen(true);
    getApprove();
  };

  const PendingState = () => {
    setOpenPending(true);
    getApprove();
  };

  const Rejected = () => {
    setOpens(true);
  };

  const ApproveFunction = () => {
    if (agentData && adminData) {
      if (agentData >= 0 && adminData >= 0 && managerData >= 0) {
        let pid = data?.product?._id
          ? data?.product?._id
          : data?.product?.length > 0
            ? data?.product[0]?._id
            : saveProduct;
        setSaveStatus(statusChange);
        let recData = {
          ...data,
          policyId: data?._id,
          productId: pid,
          policyType: data?.policyType?._id
            ? data?.policyType?._id
            : data?.policyType?.length > 0
              ? data?.policyType[0]?._id
              : savePolicyType
                ? savePolicyType
                : "",
          userId: data?.userId?._id
            ? data?.userId?._id
            : data?.userId[0]?._id
              ? data?.userId[0]?._id
              : "",
          companyId: data?.companyId?._id
            ? data?.companyId?._id
            : data?.companyId[0]?._id
              ? data?.companyId[0]?._id
              : "",
          companyCodeId :data?.companyCodeId?._id
          ? data?.policyTcompanyCodeIdype?._id
          : data?.companyCodeId?.length > 0
            ? data?.companyCodeId[0]?._id
            : saveBookingCode
              ? saveBookingCode
              : "",
          paymentMode: data?.paymentMode,
          agentShare: agentData,
          adminShare: adminData,
          managerShare: managerData,
          rtoLocation:data.rtoLocation._id ? data.rtoLocation._id : selectedRto.key ? selectedRto.key : "",
          year:data.year._id ? data.year._id : selectedYear.key ? selectedYear.key : "",
          make:data.make._id ? data.make._id : selectedMake.key ? selectedMake.key : ""

        };
        policyEntryAdmin(recData)
          .then((res) => {
            console.log(res.data);
          })
          // setApprove(res.data)
          // console.log(res.data.totalAgentShare)
          .catch((err) => {
            console.log(err);
          });
        setOpen(false);
        setOpenPending(false);
        // backToDashBoard()
      } else {
        toast.warning("Please Enter Positive number");
      }
    } else {
      if (
        saveCompany &&
        data?.policyNumber &&
        data?.customerName &&
        data?.RegistrationNumber &&
        data?.RegistrationYear &&
        data?.customerEmail &&
        data?.netPremium > 0 &&
        data?.tpPremium > 0 &&
        saveStatus
      ) {
        setSaveStatus(statusChange);
      // if (role == "manager") {
      //   setSaveStatus("pending");
      // }
      console.log("data",data)
      let recData = {
        ...data,
        policyId: data?._id,
        productId: data?.product?._id
        ? data.product._id
        : data?.product?.length > 0
          ? data?.product[0]?._id
          : saveProduct,
        policyType: data?.policyType?._id
        ? data?.policyType?._id
        : data?.policyType?.length
          ? data?.policyType[0]?._id
          : savePolicyType
            ? savePolicyType
            : "",
        userId: data?.userId?._id
          ? data?.userId?._id
          : data?.userId[0]?._id
            ? data?.userId[0]?._id
            : "",
        companyId: data?.companyId?._id
        ? data?.companyId?._id
        : data?.companyId[0]?._id
          ? data?.companyId[0]?._id
            : saveCompany ? saveCompany : "",
        companyCodeId :data?.companyCodeId?._id
        ? data?.policyTcompanyCodeIdype?._id
        : data?.companyCodeId?.length > 0
          ? data?.companyCodeId[0]?._id
          : saveBookingCode
            ? saveBookingCode
            : "",
        paymentMode: data?.paymentMode,
        agentShare: agentData,
        adminShare: adminData,
        managerShare: managerData,
        rtoLocation: data.rtoLocation._id ? data.rtoLocation._id : selectedRto.key ? selectedRto.key : "",
        year:data.year._id ? data.year._id : selectedYear.key ? selectedYear.key : "",
        make:data.make._id ? data.make._id : selectedMake.key ? selectedMake.key : ""

      };
      policyEntryAdmin(recData)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      setOpen(false);
        setOpenPending(false);
        // backToDashBoard()
      } else {
        toast.warning("Please Select All")
      }
      
    }
  };

  const EditDateBox = () => {
    setUpdate(true);
  };
  const updateDatee = () => {
    setDisabled(false);
    setUpdate(false);
  };
  const RejectedFunction = () => {
    setSaveStatus("rejected");
    setOpens(false);
  };

  const UploadFiles = (event) => {
    console.log("ragavan", event);
  };
  const handleDate = (date, dateString) => {
    setUpdateDate(dateString);
  };
  function handleDateChange(date) {
    setSelectedDate(date);
  }

  async function handleDateODPolicyStart(e) {
    let date=e
    setSelectedDateOdStart(date)
    var d = new Date(date);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day-1);
    var v = moment(c)
    console.log(date,d,c,year+1,month,day)
    console.log("after",v)
    await handleDateODPolicyEnd(v)
    setData({ ...data, odPolicyEndDate: v })
    // await setData({ ...data, odPeriod : 1 })
    // setData({ ...data, tpPeriod : 1 })
  }

  async function handleDateODPolicyEnd(date) {
    setSelectedDateOdEnd(date)
  }

  async function handleDateTpPolicyStart(date) {
    setSelectedDateTpStart(date)
    var d = new Date(date);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day-1);
    var v = moment(c)
    console.log("after1",v)
    await handleDateTpPolicyEnd(v)
    setData({ ...data, tpPolicyEndDate: v })
    // setData({ ...data, odPeriod : 1 })
    // setData({ ...data, tpPeriod : 1 })
  }
  async function handleDateTpPolicyEnd(date) {
    setSelectedDateTpEnd(date)
  }
  function handleDateClose() {
    if (!selectedDate) {
      setSelectedDate(null);
    }
  }

  return (
    <>
      <div className="formContainer h-100">
        <OverlayScrollbarsComponent className="formBody">
          <Formik innerRef={(ref) => { }}>
            {({ values }) => (
              <Form noValidate>
                <div className="innerContainer row">
                  <h5 className="col-12 mb-2" > User Details</h5>
                  <div className="dropDownContainer col-6 ">
                    <FloatLabel
                      label="User Name"
                      value={
                        viewpopulate?.userId?.userName
                          ? viewpopulate?.userId?.userName
                          : viewpopulate?.userId[0]?.userName
                      }
                    >
                      <Select
                        className="w-100"
                        value={
                          viewpopulate?.userId?.userName
                            ? viewpopulate?.userId?.userName
                            : viewpopulate?.userId[0]?.userName
                        }
                        disabled
                      ></Select>
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Branch" value={branchName}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={branchName}
                        disabled={true}
                      />
                    </FloatLabel>
                  </div>
                  <hr />
                  <h5 className="col-12 mb-2" > Policy Details</h5>
                  <div className="col-6" >
                    {/* {console.log(data.policyDate ? dayjs(data.policyDate).format("DD-MM-YYYY") : "FALSE", "RRRR")}
                    {console.log(data.policyDate ? (moment(data.policyDate).format("DD-MM-YYYY")) : "FALSE", "EEEEE")}
                    {console.log(data.policyDate ? dayjs(moment(data.policyDate).format("DD-MM-YYYY")) : selectedDate ?
                      dayjs(moment(selectedDate).format("YYYY-MM-DD")) : dayjs(moment("1977-01-01").format("YYYY-MM-DD")))}
                    {console.log(dayjs(selectedDate))} */}
                    <FloatLabel label="Policy Date" value={data.policyDate ? dayjs(data.policyDate).format("YYYY-MM-DD") : selectedDate}>
                      <DatePicker
                        className="w-100 form-control"
                        // defaultValue={data.policyDate ? dayjs(moment(data.policyDate).format("DD-MM-YYYY")) : selectedDate ?
                        //   dayjs(moment(selectedDate).format("YYYY-MM-DD")) : dayjs(moment("1977-01-01").format("YYYY-MM-DD"))}
                        // defaultValue={moment(data?.policyDate, "YYYY-MM-DD")}
                        // defaultValue={selectedDate}
                        //value={data.policyDate ? (data.policyDate) : selectedDate}
                        defaultValue={dayjs(selectedDate)}
                        format={dateFormat}
                        placeholder=""
                        onChange={handleDateChange}
                        // onClose={handleDateClose}
                        isClearable={true}
                        disabled={formType === "view"}
                        dateRender={(current) => {
                          const style = {};
                          return (
                            <div className="ant-picker-cell-inner" style={style}>
                              {current.date()}
                            </div>
                          );
                        }}
                      />
                    </FloatLabel>
                  </div>
                  {/* {formType === "edit" && (
                    <div className="col-6 mb-2 d-flex">
                      <FloatLabel label="Policy Date" value={data.policyDate}>
                        <DatePicker
                          onChange={handleDate}
                          name="paymentDate"
                          disabled={disabled ? true : false}
                          // defaultValue={moment(data?.policyDate, "YYYY-MM-DD")}
                          style={{ height: 40, width: "305px" }}
                          dateRender={(current) => {
                            const style = {};
                            return (
                              <div
                                className="ant-picker-cell-inner"
                                style={style}
                              >
                                {current.date()}
                              </div>
                            );
                          }}
                        />
                      </FloatLabel>
                      <EditOutlined
                        onClick={EditDateBox}
                        style={{
                          border: "1px solid gray",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                    </div>
                  )} */}
                  <div className="col-6">
                    <FloatLabel label="Policy Number" value={data.policyNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.policyNumber}
                        onChange={(e) =>
                          setData({ ...data, policyNumber: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="dropDownContainer col-6 ">
                    <FloatLabel label="Company Name" value={saveCompany}>
                      <Select
                        className="w-100"
                        value={saveCompany}
                        onSelect={(e,key) => {
                          getBookingCodeList(key)
                          setSaveCompany(e)
                          setSaveBookingCode()
                          setSaveProduct()
                          getProductBycomp(key)
                          getRtoBycomp(key)
                          setSelectedRto()
                          setData({ ...data, rtoLocation: { _id: "" } })
                          getYearBycomp(key)
                          getMakeBycomp(key)
                          setSelectedYear()
                          setData({ ...data, year: { _id: "" } })
                          setData({ ...data, make: {_id: ""}})
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      >
                        {mapCompany?.map((ele) => {
                          return (
                            <Option value={ele._id} key={ele._id}>
                              {ele.companyName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="dropDownContainer col-6">
                    <FloatLabel label="Product Name" value={saveProduct}>
                      <Select
                        className="w-100"
                        value={saveProduct}
                        onSelect={(e) => setSaveProduct(e)}
                        disabled={formType === "view" ? true : false}
                      >
                        {mapProduct?.map((ele) => {
                          return (
                            <Option value={ele._id} key={ele._id}>
                              {ele.productName}
                            </Option>
                          );
                        })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="dropDownContainer col-6">
                    <FloatLabel label="Policy Type" value={savePolicyType}>
                      <Select
                        className="w-100"
                        value={savePolicyType}
                        onSelect={(e) => {
                          console.log(e)
                          setSavePolicyType(e)
                          if (e === "65360b1391e3c0cba96670ee") {
                            clearTpData()
                          } else if (e === "64cfcd6fda1afd554aad9642") {
                            clearOdData()
                          } else {
                            backupODTPData()
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      >
                        {mapPolicyType?.map((ele) => {
                          return (
                            <Option value={ele._id} key={ele._id}>
                              {ele.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="dropDownContainer col-6">
                    <FloatLabel label="Booking Code" value={saveBookingCode}>
                      <Select
                        className="w-100"
                        value={saveBookingCode}
                        onSelect={(e) => {
                          setSaveBookingCode(e)
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      >
                        {mapBookingCode?.map((ele) => {
                          return (
                            <Option value={ele._id} key={ele._id}>
                              {ele.companyCode}
                            </Option>
                          );
                        })}
                      </Select>
                    </FloatLabel>
                  </div>
                  {/* <div className="col-6 mb-2" hidden={formType === "edit"}>
                    <FloatLabel label="Policy Date" value={data.policyDate}>
                      <DatePicker
                        className="w-100 form-control"
                        defaultValue={moment(data?.policyDate, "YYYY-MM-DD")}
                        disabled
                      />
                    </FloatLabel>
                  </div> */}
                  <hr />
                  <h5 className="col-12 mb-2" > Customer Details</h5>
                  <div className="col-6">
                    <FloatLabel
                      label="Customer Name"
                      value={data?.customerName}
                    >
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.customerName}
                        onChange={(e) =>
                          setData({ ...data, customerName: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Email" value={data?.customerEmail}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.customerEmail}
                        onChange={(e) =>
                          setData({ ...data, customerEmail: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel
                      label="Mobile Number"
                      value={data?.customerMobile}
                    >
                      <Field
                        type="text"
                        maxlength={10}
                        className="w-100 form-control"
                        value={data?.customerMobile}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>{
                          if (e.target.value.length <= 10 && !isNaN(e.target.value)) {
                            setData({ ...data, customerMobile: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <hr/>
                  <h5 className="col-12 mb-2" > Vehicle Details</h5>
                  <div className="col-6">
                    <FloatLabel
                      label="Registration Number"
                      value={data?.RegistrationNumber}
                    >
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.RegistrationNumber}
                        onChange={(e) => {
                          let regex = /[^\w\s/]/g;
                          const matches = e.target.value.match(regex);
                          if (matches) {
                            // setLoader(false);
                            toast.warning("Special Characters are not valid")
                          } else {
                            setData({
                              ...data,
                              RegistrationNumber: e.target.value,
                            })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel
                      label="Registration Year"
                      value={data?.RegistrationYear}
                    >
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.RegistrationYear}
                        onChange={(e) => {
                          let regex = /[^\w\s/]/g;
                          const matches = e.target.value.match(regex);
                          if (matches) {
                            // setLoader(false);
                            toast.warning("Special Characters are not valid")
                          } else {
                            setData({
                              ...data,
                              RegistrationYear: e.target.value,
                            })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Make Model" value={data?.makeModel}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.makeModel}
                        onChange={(e) =>
                          setData({ ...data, makeModel: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  
                  <div className="col-6">
                    <FloatLabel label="GVW" value={data?.gvw}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.gvw}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, gvw: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="CC" value={data?.cc}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.cc}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, cc: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Seating Capacity" value={data?.seatingCapacity}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.seatingCapacity}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes("+")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setData({ ...data, seatingCapacity: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Vehicle Subclass " value={data?.vehicleSubclass}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.vehicleSubclass}
                        onChange={(e) =>
                          setData({ ...data, vehicleSubclass: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div> */}
                  <div className="col-6">
                    <FloatLabel label="Fuel Type " value={data?.fuelType}>
                      <Select
                          className="w-100"
                          value={data?.fuelType}
                        onSelect={(e) => {
                          setData({ ...data, fuelType: e })
                          
                        }
                        }
                        
                          disabled={formType === "view" ? true : false}
                        >
                        <Option value="PETROL" key={0}>PETROL</Option>
                        <Option value="DIESEL" key={1}>DIESEL</Option>
                        <Option value="CNG" key={2}>CNG</Option>
                        <Option value="LPG" key={3}>LPG</Option>
                        <Option value="GAS" key={4}>GAS</Option>
                        <Option value="ELECTRIC" key={5}>ELECTRIC</Option>
                      </Select>
                      {/* <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.fuelType}
                        onChange={(e) =>
                          setData({ ...data, fuelType: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      /> */}
                    </FloatLabel>
                  </div>
                  <hr/>
                  <h5 className="col-12 mb-2" > RTO Details</h5>
                  <div className="col-6">
                    <FloatLabel label="RTO Location" value={selectedRto}>
                      <Select
                        showSearch
                        className="w-100"
                        value={selectedRto?.rtoName ? selectedRto?.rtoName : selectedRto}
                        onSelect={(e, key) => {
                          setSelectedRto(key)
                          setData({ ...data, rtoLocation: {_id:key.key}})
                        }}
                        disabled={formType === "view" ? true : false}

                      >
                       {rtoList?.length > 0 &&
                          rtoList.map((el) => {
                            return (
                              <Option value={el.rtoName} key={el._id}>
                                {el.rtoName}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Year" value={selectedYear}>
                      <Select
                        showSearch
                        className="w-100"
                        value={selectedYear?.year ? selectedYear?.year : selectedYear}
                        onSelect={(e, key) => {
                          setSelectedYear(key)
                          setData({ ...data, year: {_id:key.key}})
                        }}
                        disabled={formType === "view" ? true : false}

                      >
                       {yearList?.length > 0 &&
                          yearList.map((el) => {
                            return (
                              <Option value={el.year} key={el._id}>
                                {el.year}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Make" value={selectedMake}>
                      <Select
                        showSearch
                        className="w-100"
                        value={selectedMake?.make ? selectedMake?.make : selectedMake}
                        onSelect={(e, key) => {
                          setSelectedMake(key)
                          setData({ ...data, make: {_id:key.key}})
                        }}
                        disabled={formType === "view" ? true : false}

                      >
                       {makeList?.length > 0 &&
                          makeList.map((el) => {
                            return (
                              <Option value={el.make} key={el._id}>
                                {el.make}
                              </Option>
                            );
                          })}
                      </Select>
                    </FloatLabel>
                  </div>
                  <hr />
                  {
                    savePolicyType !== "64cfcd6fda1afd554aad9642" && 
                    <>
                    <h5 className="col-12 mb-2" > OD Policy Date</h5>
                    <div className="col-6" 
                    style={{ cursor: 'pointer' }}>
                      <FloatLabel label="OD Policy Start Date" value={selectedDateOdStart ? selectedDateOdStart : data.odPolicyStartDate ?
                            (dayjs(data.odPolicyStartDate)) : null}>
                        <DatePicker
                            name="paymentDate"
                            className="w-100 form-control"
                            placeholder=""
                          onChange={handleDateODPolicyStart}
                          value={selectedDateOdStart ? dayjs(selectedDateOdStart) : data.odPolicyStartDate ?
                            (dayjs(data.odPolicyStartDate)) : null}
                            style={{ height: 40 }}
                            dateRender={(current) => {
                              const style = {};
                              // if (current.date() === 1) {
                              //   style.border = "1px solid #1890ff";
                              //   style.borderRadius = "50%";
                              // }
                              return (
                                <div className="ant-picker-cell-inner" style={style}>
                                  {current.date()}
                                </div>
                              );
                            }}
                      />
                        {/* <Input
                          id="dateInput"
                          type='date'
                          name="OD Policy Start Date"
                          className="w-100 form-control"
                          placeholder=""
                          onChange={(e) => {
                            console.log(e.target.value)
                          }}
                          style={{ height: 40 }}
                          value={data.odPolicyStartDate ? data.odPolicyStartDate : selectedDateOdStart ?
                            (selectedDateOdStart.toISOString().split('T')[0]) : (new Date().toISOString().split('T')[0])}
                        /> */}
                        {/* <DatePicker
                          // className="w-100 form-control"
                          defaultValue={data.odPolicyStartDate ? ((data.odPolicyStartDate)) : selectedDateOdStart ?
                            selectedDateOdStart : (moment().format("YYYY-MM-DD"))}
                          placeholder=""
                          format="YYYY-MM-DD" 
                          onChange={handleDateODPolicyStart}
                          // onClose={handleDateClose}
                          isClearable={true}
                          disabled={formType === "view"}
                          // dateRender={(current) => {
                          //   const style = {};
                          //   return (
                          //     <div className="ant-picker-cell-inner" style={style}>
                          //       {current.date()}
                          //     </div>
                          //   );
                          // }}
                        /> */}
                      </FloatLabel>
                    </div>
                    <div className="col-6">
                      <FloatLabel label="OD Policy End Date" value={selectedDateOdEnd ? (selectedDateOdEnd) : data.odPolicyEndDate ?
                        (data.odPolicyEndDate) :null}>
                        <DatePicker
                            id="OD policyenddate"
                            placeholder=""
                          name="PolicyEndDate"
                          className="w-100 form-control"
                          value={selectedDateOdEnd ? dayjs(selectedDateOdEnd) : data.odPolicyEndDate ?
                            dayjs(data.odPolicyEndDate) : null}
                            onChange={handleDateODPolicyEnd}
                            style={{ height: 40 }}
                            dateRender={(current) => {
                              const style = {};
                              // if (current.date() === 1) {
                              //   style.border = "1px solid #1890ff";
                              //   style.borderRadius = "50%";
                              // }
                              return (
                                <div className="ant-picker-cell-inner" style={style}>
                                  {current.date()}
                                </div>
                              );
                            }}
                          />
                        {/* <Input
                          type='date'
                          name="paymentDate"
                          className="w-100"
                          placeholder=""
                          onChange={handleDateODPolicyEnd}
                          style={{ height: 40 }}
                          value={data.odPolicyEndDate ? (data.odPolicyEndDate) : selectedDateOdEnd ?
                            (selectedDateOdEnd) : (moment(new Date()))}
                        /> */}
                        {/* <DatePicker
                          // id="OD policyenddate"
                          // name="OD PolicyEndDate"
                          // className="w-100 form-control"
                          placeholder=""
                          format="YYYY-MM-DD" 
                          defaultValue={data.odPolicyEndDate ? (moment(data.odPolicyEndDate).format("YYYY-MM-DD")) : selectedDateOdEnd ?
                            (moment(selectedDateOdEnd).format("YYYY-MM-DD")) : (moment(new Date()).format("YYYY-MM-DD"))}
                          onChange={handleDateODPolicyEnd}
                          // onClose={handleDateClose}
                          isClearable={true}
                          disabled={formType === "view"}
                          // style={{ height: 40 }}
                          // dateRender={(current) => {
                          //   const style = {};
                          //   // if (current.date() === 1) {
                          //   //   style.border = "1px solid #1890ff";
                          //   //   style.borderRadius = "50%";
                          //   // }
                          //   return (
                          //     <div className="ant-picker-cell-inner" style={style}>
                          //       {current.date()}
                          //     </div>
                          //   );
                          // }}
                        /> */}
                      </FloatLabel>
                    </div>
                    <div className="col-6 ">
                      <FloatLabel label="OD Period" value={data?.odPeriod}>
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={data?.odPeriod}
                          onChange={(e) =>
                            setData({ ...data, odPeriod: e.target.value })
                          }
                          disabled={formType === "view" ? true : false}
                        />
                      </FloatLabel>
                    </div>
                    <hr/>
                    </>
                  }
                  {
                    savePolicyType !== "65360b1391e3c0cba96670ee" &&
                    <>
                  <h5 className="col-12 mb-2" > TP Policy Date</h5>
                  <div className="col-6">
                    <FloatLabel label="TP Policy Start Date" value={selectedDateTpStart ? (selectedDateTpStart) : data.tpPolicyStartDate ?
                          (data.tpPolicyStartDate) : null}>
                      <DatePicker
                        id="tp policystartdate"
                            name="policystartdate"
                            placeholder=""
                        className="w-100 form-control"
                        value={selectedDateTpStart ? dayjs(selectedDateTpStart) : data.tpPolicyStartDate ?
                          dayjs(data.tpPolicyStartDate) : null }
                          onChange={handleDateTpPolicyStart}
                          style={{ height: 40 }}
                          dateRender={(current) => {
                            const style = {};
                            // if (current.date() === 1) {
                            //   style.border = "1px solid #1890ff";
                            //   style.borderRadius = "50%";
                            // }
                            return (
                              <div className="ant-picker-cell-inner" style={style}>
                                {current.date()}
                              </div>
                            );
                          }}
                      />
                      {/* <DatePicker
                        name="TP PolicyEndDate"
                        className="w-100 form-control"
                        placeholder=""
                        defaultValue={data.tpPolicyStartDate ? dayjs(moment(data.tpPolicyStartDate)) : selectedDateTpStart ?
                          dayjs(moment(selectedDateTpStart)) : dayjs(moment(data.policyDate).format("YYYY-MM-DD"))}
                        format={dateFormat}
                        onChange={handleDateTpPolicyStart}
                        isClearable={true}
                        disabled={formType === "view"}
                        style={{ height: 40 }}
                        dateRender={(current) => {
                          const style = {};
                          return (
                            <div className="ant-picker-cell-inner" style={style}>
                              {current.date()}
                            </div>
                          );
                        }}
                      /> */}
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="TP Policy End Date" value={selectedDateTpEnd ? (selectedDateTpEnd) : data.tpPolicyEndDate ?
                            (data.tpPolicyEndDate) : null}>
                      <DatePicker
                        id="tp policystartdate"
                        name="policystartdate"
                        className="w-100 form-control"
                          value={selectedDateTpEnd ? dayjs(selectedDateTpEnd) : data.tpPolicyEndDate ?
                            dayjs(data.tpPolicyEndDate) : null}
                          onChange={handleDateTpPolicyEnd}
                            style={{ height: 40 }}
                            placeholder=""
                          dateRender={(current) => {
                            const style = {};
                            // if (current.date() === 1) {
                            //   style.border = "1px solid #1890ff";
                            //   style.borderRadius = "50%";
                            // }
                            return (
                              <div className="ant-picker-cell-inner" style={style}>
                                {current.date()}
                              </div>
                            );
                          }}
                        />
                      {/* <DatePicker
                        name="TP PolicyEndDate"
                        className="w-100 form-control"
                        placeholder=""
                        defaultValue={data.tpPolicyEndDate ? dayjs(moment(data.tpPolicyEndDate)) : selectedDateTpEnd ?
                          dayjs(moment(selectedDateTpEnd)) : dayjs(moment().format("YYYY-MM-DD"))}
                        format={dateFormat}
                        onChange={handleDateTpPolicyEnd}
                        isClearable={true}
                        disabled={formType === "view"}
                        style={{ height: 40 }}
                        dateRender={(current) => {
                          const style = {};
                          return (
                            <div className="ant-picker-cell-inner" style={style}>
                              {current.date()}
                            </div>
                          );
                        }}
                      /> */}
                    </FloatLabel>
                  </div>
                  <div className="col-6 ">
                    <FloatLabel label="TP Period" value={data?.tpPeriod}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.tpPeriod}
                        onChange={(e) =>
                          setData({ ...data, tpPeriod: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <hr/>
                    </>
                  }
                  <h5 className="col-12 mb-2" > Premium Details</h5>
                  <div className="col-6">
                    <FloatLabel label="CPA Cover" value={data?.cpaCover}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.cpaCover}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, cpaCover: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="OD Discount" value={data?.odDiscount}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.odDiscount}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, odDiscount: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="OD Premium" value={data?.odPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.odPremium}
                        onChange={(e) =>
                          setData({ ...data, odPremium: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="TP Premium" value={data?.tpPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.tpPremium}
                        onChange={(e) =>
                          setData({ ...data, tpPremium: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  <div className="col-6">
                    <FloatLabel label="Net Premium" value={data?.netPremium}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.netPremium}
                        onChange={(e) =>
                          setData({ ...data, netPremium: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Tax Amount" value={data?.tax}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.tax}
                        onChange={(e) =>
                          setData({ ...data, tax: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div> */}

                  <div className="col-6">
                    <FloatLabel
                      label="Total Premium"
                      value={data?.totalPremium}
                    >
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.totalPremium}
                        onChange={(e) =>
                          setData({ ...data, totalPremium: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div>
                  {/* {data?.paymentMode === "cash" && (
                    <div className="col-6 mb-2">
                      <FloatLabel
                        label="Cash Amount"
                        value={data?.CashModeAmount}
                      >
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={data?.CashModeAmount}
                          onChange={(e) =>
                            setData({ ...data, CashModeAmount: e.target.value })
                          }
                          disabled={formType === "view" ? true : false}
                        />
                      </FloatLabel>
                    </div>
                  )} */}
                  
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Fuel Type " value={data?.fuelType}>
                      <Select
                          className="w-100"
                          value={data?.fuelType}
                        onSelect={(e) => {
                          setData({ ...data, fuelType: e })
                          
                        }
                        }
                        
                          disabled={formType === "view" ? true : false}
                        >
                        <Option value="PETROL" key={0}>PETROL</Option>
                        <Option value="DIESEL" key={1}>DIESEL</Option>
                        <Option value="CNG" key={2}>CNG</Option>
                        <Option value="LPG" key={3}>LPG</Option>
                        <Option value="GAS" key={4}>GAS</Option>
                        <Option value="ELECTRIC" key={5}>ELECTRIC</Option>
                      </Select>
                      {/* <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.fuelType}
                        onChange={(e) =>
                          setData({ ...data, fuelType: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      /> */}
                    {/* </FloatLabel>
                  </div>  */}
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Engine Number " value={data?.engineNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.engineNumber}
                        onChange={(e) =>
                          setData({ ...data, engineNumber: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div> */}
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Chassis Number " value={data?.chassisNumber}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.chassisNumber}
                        onChange={(e) =>
                          setData({ ...data, chassisNumber: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div> */}
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="NCB" value={data?.ncb}>
                    <Select
                          className="w-100"
                          value={data?.ncb}
                        onSelect={(e) => {
                          setData({ ...data, ncb: e })
                          
                        }
                        }
                        
                          disabled={formType === "view" ? true : false}
                        >
                        <Option value="0" key={0}>0</Option>
                        <Option value="20" key={1}>20</Option>
                        <Option value="25" key={2}>25</Option>
                        <Option value="35" key={3}>35</Option>
                        <Option value="45" key={4}>45</Option>
                        <Option value="50" key={5}>50</Option>
                      </Select>
                      {/* <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.ncb}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setData({ ...data, ncb: e.target.value })
                          }
                        }
                        }
                        disabled={formType === "view" ? true : false}
                      /> */}
                    {/* </FloatLabel>
                  </div>  */}
                  
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Buiness Type" value={data?.businessType}>
                    <Select
                          className="w-100"
                          value={data?.businessType}
                        onSelect={(e) => {
                          setData({ ...data, businessType: e })
                          
                        }
                        }
                        
                          disabled={formType === "view" ? true : false}
                        >
                        <Option value="NEW" key={0}>NEW</Option>
                        <Option value="ROLL OVER" key={1}>ROLL OVER</Option>
                        <Option value="USED" key={2}>USED</Option>
                      </Select> */}
                      {/* <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.businessType}
                        onChange={(e) =>
                          setData({ ...data, businessType: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      /> */}
                    {/* </FloatLabel>
                  </div> */}
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="Carrier Type" value={data?.carrierType}>
                    <Select
                          className="w-100"
                          value={data?.carrierType}
                        onSelect={(e) => {
                          setData({ ...data, carrierType: e })
                          
                        }
                        }
                        
                          disabled={formType === "view" ? true : false}
                        >
                        <Option value="PUBLIC" key={0}>PUBLIC</Option>
                        <Option value="PRIVATE" key={1}>PRIVATE</Option>
                      </Select> */}
                      {/* <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.carrierType}
                        onChange={(e) =>
                          setData({ ...data, carrierType: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      /> */}
                    {/* </FloatLabel>
                  </div> */}
                  {/* <div className="col-6 mb-2">
                    <FloatLabel label="RTO" value={data?.RTO}>
                      <Field
                        type="text"
                        className="w-100 form-control"
                        value={data?.RTO}
                        onChange={(e) =>
                          setData({ ...data, RTO: e.target.value })
                        }
                        disabled={formType === "view" ? true : false}
                      />
                    </FloatLabel>
                  </div> */}
                  <hr/>
                  <h5 className="col-12 mb-2" >Payment Mode</h5>
                  <div className="col-6">
                    <FloatLabel label="Payment Mode" value={data?.paymentMode}>
                      <Select
                        showSearch
                        className="w-100"
                        value={data?.paymentMode}
                        onSelect={(e) => {
                          setData({ ...data, paymentMode: e})
                        }}
                        disabled={formType === "view" ? true : false}

                      >
                        <option value="cash">Cash</option>
                        <option value="online">Online</option>
                      </Select>
                    </FloatLabel>
                  </div>
                  {
                    data.paymentMode==='cash' && 
                    <div className="col-6">
                      <FloatLabel label="Cash Mode Amount" value={data?.CashModeAmount}>
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={data?.CashModeAmount}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setData({ ...data, CashModeAmount: e.target.value })
                            }
                          }
                          }
                          disabled={formType === "view" ? true : false}
                        />
                      </FloatLabel>
                  </div>
                  }
                  <hr/>
                  <h5 className="col-12 mb-2" >Others</h5>
                  <div className="col-6">
                    <FloatLabel label="Policy Status" value={saveStatus}>
                      <Select
                        className="w-100"
                        value={saveStatus}
                        onSelect={(e) => {
                          console.log(e);
                          if (e === "approved") {
                            setStatusChange(e);
                            Approved();
                          } else if (e === "pending") {
                            setStatusChange(e);
                            PendingState();
                          } else if (e === "rejected") {
                            setStatusChange(e);
                            Rejected();
                          } else {
                            setStatusChange(e);
                            setSaveStatus(e);
                          }
                        }}
                        disabled={formType === "view" ? true : false}
                      >
                        <Option value="entry pending">Entry Pending</Option>
                        <Option value="pending">Pending</Option>
                        <Option value="rejected">Rejected</Option>
                        {role === "admin" && (
                          <Option value="approved">Approved</Option>
                        )}
                      </Select>
                    </FloatLabel>
                  </div>
                  {saveStatus === "rejected" && (
                    <div className="col-12">
                      <FloatLabel
                        label="Rejection Remarks "
                        value={data?.RejectedRemarks}
                      >
                        <Field
                          type="text"
                          className="w-100 form-control"
                          value={data?.RejectedRemarks}
                          onChange={(e) =>
                            setData({
                              ...data,
                              RejectedRemarks: e.target.value,
                            })
                          }
                          disabled={formType === "view" ? true : false}
                        />
                      </FloatLabel>
                    </div>
                  )}
                  <div className="col-6">
                    <Button
                      onClick={DownloadFunction}
                      style={{ borderRadius: "0px", height: "40px" }}
                    >
                      Download
                    </Button>
                  </div>
                  <div className="file-container" hidden={formType === "view"}>
                    <div className="file-upload">
                      <input type="file" name="file" onChange={changeHandler} />
                    </div>
                  </div>
                  <hr />
                </div>
              </Form>
            )}
          </Formik>
        </OverlayScrollbarsComponent>
      </div>
      <footer>
        {loader ? (
          <Stack
            style={{
              position: "fixed",
              right: "50%",
              bottom: "50%",
            }}
          >
            <div class="loader">
              Loading
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Stack>
        ) : null}
        <Button className="button transButton" onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          className="button"
          hidden={formType === "view" || loader}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </footer>

      <ConfirmBox
        open={opens}
        closeDialog={() => setOpens(false)}
        Function={RejectedFunction}
        tittle={""}
        confirmButton={"Reject"}
        heading={"Reject"}
        Content={"Are you Sure want to Reject"}
      />
      <ConfirmBox
        open={update}
        heading={"Update"}
        closeDialog={() => setUpdate(false)}
        Function={updateDatee}
        Content={"You are Attempting to Edit Policy Date"}
        confirmButton={"Edit"}
      />

      <div className="ApprovePopup">
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[800],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="alert-dialog-title">Approve</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="popupTable">
                <table>
                  <tr>
                    <th></th>
                    <th>Od</th>
                    <th>Tp</th>
                    <th>Net</th>
                    <th>Total</th>
                  </tr>
                  <tr>
                    <td>Share Percentage</td>
                    <td>{approve?.odPercent}</td>
                    <td>{approve?.tpPercent}</td>
                    <td>{approve?.netPercent}</td>
                    <td rowSpan={3}>{approve?.totalPremium}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{approve?.odPremium}</td>
                    <td>{approve?.tpPremium}</td>
                    <td>{approve?.netPremium}</td>
                  </tr>
                  <tr>
                    <td>Share Amount</td>
                    <td>{approve?.odShare}</td>
                    <td>{approve?.tpShare}</td>
                    <td>{approve?.netShare}</td>
                  </tr>
                  <tr>
                    <td>Agent Share</td>
                    <td colSpan={4}>
                      <Input
                        style={{ border: "none", boxShadow: "none" }}
                        type="text"
                        min={0}
                        // step={1}
                        name="agentshare"
                        id="agentshare"
                        placeholder="Enter Agent Share"
                        defaultValue={approve?.totalAgentShare}
                        key={approve?.totalAgentShare}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            // setApprove({
                            //   ...approve,
                            //   totalAgentShare: e.target.value,
                            // });
                            setAgentData(e.target.value);
                          }
                        }}
                      />
                    </td>
                  </tr>
                  {role == "admin" && (
                    <tr>
                      <td>Manager Share</td>
                      <td colSpan={4}>
                        <Input
                          type="text"
                          min={0}
                          // step={1}
                          name="managershare"
                          id="managershare"
                          style={{ border: "none", boxShadow: "none" }}
                          defaultValue={approve?.manager?.totalManagerShare}
                          key={approve?.manager?.totalManagerShare}
                          placeholder="Enter Admin Share"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setApprove({
                                ...approve,
                                managerShare: e.target.value,
                              });
                              setManagerData(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {role == "admin" && (
                    <tr>
                      <td>Admin Share</td>
                      <td colSpan={4}>
                        <Input
                          type="text"
                          min={0}
                          // step={1}
                          name="adminshare"
                          id="adminshare"
                          style={{ border: "none", boxShadow: "none" }}
                          defaultValue={approve?.totalAdminShare}
                          key={approve?.totalAdminShare}
                          placeholder="Enter Admin Share"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setApprove({
                                ...approve,
                                adminShare: e.target.value,
                              });
                              setAdminData(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {role == "manager" && (
                    <tr>
                      <td>Manager Share</td>
                      <td colSpan={4}>
                        <Input
                          type="text"
                          min={0}
                          // step={1}
                          name="adminshare"
                          id="adminshare"
                          style={{ border: "none", boxShadow: "none" }}
                          defaultValue={approve?.manager?.totalManagerShare}
                          key={approve?.manager?.totalManagerShare}
                          placeholder="Enter Admin Share"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setApprove({
                                ...approve,
                                managerShare: e.target.value,
                              });
                              setManagerData(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={() => setOpen(false)}>Cancel</Buttons>
            <Buttons
              onClick={ApproveFunction}
              autoFocus
              varient="outlined"
              color="warning"
            >
              Approve
            </Buttons>
          </DialogActions>
        </Dialog>
      </div>
      <div className="PendingPopup">
        <Dialog
          open={openPending}
          onClose={() => setOpenPending(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpenPending(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[800],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="alert-dialog-title">Pending</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="popupTable">
                <table>
                  <tr>
                    <th></th>
                    <th>Od</th>
                    <th>Tp</th>
                    <th>Net</th>
                    <th>Total</th>
                  </tr>
                  <tr>
                    <td>Share Percentage</td>
                    <td>{approve?.odPercent}</td>
                    <td>{approve?.tpPercent}</td>
                    <td>{approve?.netPercent}</td>
                    <td rowSpan={3}>{approve?.totalPremium}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{approve?.odPremium}</td>
                    <td>{approve?.tpPremium}</td>
                    <td>{approve?.netPremium}</td>
                  </tr>
                  <tr>
                    <td>Share Amount</td>
                    <td>{approve?.odShare}</td>
                    <td>{approve?.tpShare}</td>
                    <td>{approve?.netShare}</td>
                  </tr>
                  <tr>
                    <td>Agent Share</td>
                    <td colSpan={4}>
                      <Input
                        style={{ border: "none", boxShadow: "none" }}
                        type="text"
                        min={0}
                        // step={1}
                        name="agentshare"
                        id="agentshare"
                        placeholder="Enter Agent Share"
                        defaultValue={approve?.totalAgentShare}
                        key={approve?.totalAgentShare}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            // setApprove({
                            //   ...approve,
                            //   totalAgentShare: e.target.value,
                            // });
                            setAgentData(e.target.value)
                          }
                        }}
                      />
                    </td>
                  </tr>
                  {role == "admin" && (
                    <tr>
                      <td>Manager Share</td>
                      <td colSpan={4}>
                        <Input
                          type="text"
                          min={0}
                          // step={1}
                          name="managentshare"
                          id="managentshare"
                          style={{ border: "none", boxShadow: "none" }}
                          defaultValue={approve?.manager?.totalManagerShare}
                          key={approve?.manager?.totalManagerShare}
                          placeholder="Enter Admin Share"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setApprove({
                                ...approve,
                                managerShare: e.target.value,
                              });
                              setManagerData(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {role == "admin" && (
                    <tr>
                      <td>Admin Share</td>
                      <td colSpan={4}>
                        <Input
                          type="text"
                          min={0}
                          step={1}
                          style={{ border: "none", boxShadow: "none" }}
                          defaultValue={approve?.totalAdminShare}
                          key={approve?.totalAdminShare}
                          placeholder="Enter Admin Share"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setApprove({
                                ...approve,
                                adminShare: e.target.value,
                              });
                              setAdminData(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {role == "manager" && (
                    <tr>
                      <td>Manager Share</td>
                      <td colSpan={4}>
                        <Input
                          type="text"
                          min={0}
                          step={1}
                          style={{ border: "none", boxShadow: "none" }}
                          defaultValue={approve?.manager?.totalManagerShare}
                          key={approve?.manager?.totalManagerShare}
                          placeholder="Enter Admin Share"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key) && !event.key.includes(".")) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setApprove({
                                ...approve,
                                managerShare: e.target.value,
                              });
                              setManagerData(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={() => setOpenPending(false)}>Cancel</Buttons>
            <Buttons
              onClick={ApproveFunction}
              autoFocus
              varient="outlined"
              color="warning"
            >
              Update
            </Buttons>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddPolicyEntryPending;
