import React from "react";
//login
import Login from "../Page/Login/index";
//Dashboard 
import Dashboard from "../Page/Dashboard/index";

const routes = [
  {
    path: "/login",
    exact: false,
    element: Login,
    isAuthenticated: false,
  },
  {
    path: "/",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/admin/dashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/admin/newdashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  // {
  //   path: "Payout Points",
  //   exact: false,
  //   element: Dashboard,
  //   isAuthenticated: true,
  // },
  {
    path: "/manager/dashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/agent/dashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/manager/newdashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  // {
  //   path: "/agent/newdashboard",
  //   exact: false,
  //   element: Dashboard,
  //   isAuthenticated: true,
  // },
  {
    path: "/policytype",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },

  {
    path: "/policyview",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/SearchPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/branch",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/rto",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/year",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/make",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/report",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/notification",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/adduser",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/userconfig",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/company",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/product",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/companycode",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/companylogin",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policycreate",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policyEntryPending",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policyagent",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/companycontact",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/companyLogin",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/renewal",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/managewallet",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/profile",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/payout",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/payoutPoints",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/transaction",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/reports",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/manager/wallet",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/manager/transaction",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/approvedPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },

];

export default routes;
