import {
  InboxIcon,
  DashboardIcon,
  PolicyIcon,
  MapsHomeWorkIcon,
  ReportIcon,
  ContactPageIcon,
  NotificationsIcon,
  StoreIcon,
  ForwardIcon,
  TollIcon
} from "../Resources/Icons/Icons";
export const menuItemsAdmin = [
  // {
  //   name: "Dashboard",
  //   icon: <DashboardIcon />,
  //   path: "/admin/dashboard",
  // },
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    path: "/admin/newdashboard",
  },
  {
    name: "Policy",
    path: "/policy",
    icon: <PolicyIcon />,
    defaultCollapsed: false,
    children: [
      {
        name: "Policy Create",
        path: "/policycreate",
        icon: <ForwardIcon />,
      },
      {
        name: "Policy List",
        path: "/policyEntryPending",
        icon: <ForwardIcon />,
      },
      // {
      //   name: "View Mapping Request",
      //   path: "/policyview",
      //   icon: <ForwardIcon />,
      // },
      {
        name: "Search Policy",
        path: "/SearchPolicy",
        icon: <ForwardIcon />,
      },
      {
        name: "Approved Policy",
        path: "/approvedPolicy",
        icon: <ForwardIcon />,
      },
    ],
  },
  {
    name: "Company Login",
    icon: <StoreIcon />,
    path: "/companyLogin",
  },
  {
    name: "Master",
    icon: <MapsHomeWorkIcon />,
    defaultCollapsed: false,
    children: [
      {
        name: "Branch",
        path: "/branch",
        icon: <ForwardIcon />,
      },
      {
        name: "RTO",
        path: "/rto",
        icon: <ForwardIcon />,
      },
      {
        name: "Year",
        path: "/year",
        icon: <ForwardIcon />,
      },
      {
        name: "Make",
        path: "/make",
        icon: <ForwardIcon />,
      },
      {
        name: "Company",
        path: "/company",
        icon: <ForwardIcon />,
      },
      {
        name: "Product",
        path: "/product",
        icon: <ForwardIcon />,
      },
      {
        name: "Policy Type",
        path: "/policytype",
        icon: <ForwardIcon />,
      },
      {
        name: "Company Code",
        path: "/companycode",
        icon: <ForwardIcon />,
      },
      {
        name: "Company Login",
        path: "/companylogin",
        icon: <ForwardIcon />,
      },
      {
        name: "Company Contact",
        path: "/companycontact",
        icon: <ForwardIcon />,
      },
    ],
  },
  {
    name: "Report",
    icon: <ReportIcon />,
    defaultCollapsed: false,
    children: [
      // {
      //   name: "Booking Data",
      //   path: "/report",
      //   icon: <ForwardIcon />,
      // },
      {
        name: "Reports",
        path: "/reports",
        icon: <ForwardIcon />,
      },
    ],
  },
  {
    name: "Manage User",
    icon: <ContactPageIcon />,
    defaultCollapsed: false,
    children: [
      {
        name: "Add User",
        path: "/adduser",
        icon: <ForwardIcon />,
      },
      {
        name: "User Config",
        path: "/userconfig",
        icon: <ForwardIcon />,
      },
    ],
  },
  {
    name: "Payout",
    icon: <StoreIcon />,
    path: "/payout"
  },
  {
    name: "Renewal",
    icon: <TollIcon />,
    path: "/renewal",
  },
  {
    name: "Notification",
    icon: <NotificationsIcon />,
    path: "/notification",
    defaultCollapsed: false,
  }
];


export const accountMenuItems = [
  {
    name: "Profile",
    icon: <InboxIcon />,
    path: "/profile",
  }
];
